@media (max-width: 480px) {
  .Subject {
    background: #212025;
    width: 100%;
    padding-top: 40px;
  }
  .line-title-subject {
    background-color: #3a3842;
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-radius: 30px;
  }

  .subject-title {
    background: linear-gradient(
      180deg,
      rgb(237, 26, 59) 0%,
      rgb(245, 131, 69) 100%
    );
    -webkit-background-clip: text !important;
    font-family: "SVN-Gilroy-Heavy", Helvetica;
    font-weight: 400;
    color: transparent;
    font-size: 1.5rem;
    padding: 10px;
    padding-left: 20px;
  }

  .subject-body {
    padding-top: 40px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .subject-item-title {
    width: 100%;
  }

  .subject-item {
    a {
      position: relative;
    }
    .subject-item-title-text {
      position: absolute;
      top: -50px;
      left: 120px;
      font-family: "SVN-Gilroy-Bold", Helvetica;
      font-weight: 700;
      color: #ffffff;
      font-size: 1.5em;
    }

    img {
      border-radius: 30px;
      width: 100%;
    }
  }

  .subject {
    width: 100%;
  }
}

@media (min-width: 481px) and (max-width: 1223px) {
  .Subject {
    background: #212025;
    width: 90%;
    display: block;

    padding-top: 40px;
  }

  .subject-container {
    max-width: 1200px;
    margin: 0 auto;
  }

  .subject-item-title {
    width: 100%;
  }

  .line-title-subject {
    background-color: #3a3842;
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-radius: 30px;
    .subject-union {
      width: 3%;
    }
  }

  .subject-title {
    background: linear-gradient(
      180deg,
      rgb(237, 26, 59) 0%,
      rgb(245, 131, 69) 100%
    );
    -webkit-background-clip: text !important;
    font-family: "SVN-Gilroy-Heavy", Helvetica;
    font-weight: 400;
    color: transparent;
    font-size: 2.5rem;
    padding: 10px;
    padding-left: 20px;
  }

  .subject-body {
    padding-top: 40px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .subject-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .subjectImg {
      width: 100%;
      border-radius: 30px;
    }
  }
}

@media (min-width: 1224px) {
  .Subject {
    background: #212025;
    width: 100%;
    display: block;
    padding-top: 40px;
  }

  .subject-container {
    max-width: 1200px;
    margin: 0 auto;
  }

  .subject-item-title {
    width: 100%;
  }

  .line-title-subject {
    background-color: #3a3842;
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-radius: 30px;
    .subject-union {
      width: 3%;
    }
  }

  .subject-title {
    background: linear-gradient(
      180deg,
      rgb(237, 26, 59) 0%,
      rgb(245, 131, 69) 100%
    );
    -webkit-background-clip: text !important;
    font-family: "SVN-Gilroy-Heavy", Helvetica;
    font-weight: 400;
    color: transparent;
    font-size: 2.5rem;
    padding: 10px;
    padding-left: 20px;
  }

  .subject-body {
    padding-top: 40px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .subject-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .subjectImg {
      border-radius: 30px;
      width: 100%;
    }
  }
}
