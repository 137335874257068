#btn-back-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: none;
    z-index: 9999999999;
    border-radius: 50%;
    font-size: 1rem;
    width: 40px;
    height: 40px;
    align-self: center;
}