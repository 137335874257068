@font-face {
  font-family: "Thevoice";
  src: url("../fonts/SVN-THE-VOICE.otf") format("opentype");
}
@media (min-width: 1224px) {
  .Header {
    display: flex;
    flex-direction: col;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: linear-gradient(
      to right,
      rgb(237, 26, 59) 0%,
      rgb(245, 131, 69) 100%
    );
  }
  .header-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 20px 40px;
  }

  .header-leftside {
    width: 200px;
    img {
      width: 100%;
    }
  }

  .header-rightside {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
    .group-buttons {
      display: flex;
      flex-direction: row;
      gap: 10px;
      a {
        padding: 20px 30px;
        font-family: "SVN-Gilroy-Bold", Helvetica;
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient(45deg, #a200ff, #6e1df4);
        border-radius: 50px;
        color: white;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
        font-weight: 600;
        font-size: 1.2rem;
        transition: all 0.2s ease-in-out;
      }
    }
    .hotline {
      font-family: "SVN-Gilroy-Bold", Helvetica;
      color: white;
      font-size: 1.2rem;
      font-weight: 600;
      text-shadow: 2px 4px 5px rgba(0, 0, 0, 1);
    }
  }

  .header-rightside a:hover {
    transform: scale(1.1);
    background: linear-gradient(45deg, #0044ff, #00b7ff);
  }

  .slogan {
    color: #fff;
    font-size: 2em;
    text-align: center;
    font-weight: 600;
    font-family: "Thevoice", Helvetica;
    line-height: 1.5;
    text-shadow: 2px 4px 5px rgba(0, 0, 0, 1);
  }
}

@media (min-width: 481px) and (max-width: 1223px) {
  .Header {
    display: flex;
    flex-direction: col;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: linear-gradient(
      to right,
      rgb(237, 26, 59) 0%,
      rgb(245, 131, 69) 100%
    );
  }
  .header-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 20px 20px;
  }

  .header-leftside {
    width: 200px;
    img {
      width: 100%;
    }
  }

  .header-rightside {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    justify-content: center;
  }

  .header-rightside a {
    padding: 15px 30px;
    font-family: "SVN-Gilroy-Bold", Helvetica;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(45deg, #a200ff, #6e1df4);
    border-radius: 50px;
    color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
    font-weight: 600;
    font-size: 14px;
    transition: all 0.2s ease-in-out;
    white-space: nowrap;
  }

  .header-rightside a:hover {
    transform: scale(1.1);
    background: linear-gradient(45deg, #0044ff, #00b7ff);
  }

  .slogan {
    color: #fff;
    font-size: 1em;
    text-align: center;
    font-weight: 600;
    font-family: "Thevoice", Helvetica;
    line-height: 1.5;
  }
}

@media (max-width: 480px) {
  .Header {
    display: flex;
    flex-direction: col;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: linear-gradient(
      to right,
      rgb(237, 26, 59) 0%,
      rgb(245, 131, 69) 100%
    );
  }
  .header-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px 20px;
  }

  .header-leftside {
    width: 200px;
    img {
      width: 100%;
    }
    flex: 1;
  }

  .header-rightside {
    flex: 2;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: right;
  }

  .header-rightside a {
    font-family: "SVN-Gilroy-Bold", Helvetica;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(45deg, #a200ff, #6e1df4);
    border-radius: 50px;
    color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
    font-weight: 600;
    font-size: 0.8rem;
    padding: 10px 10px;
    transition: all 0.2s ease-in-out;
  }

  .header-rightside a:hover {
    transform: scale(1.1);
    background: linear-gradient(45deg, #0044ff, #00b7ff);
  }

  .slogan {
    display: none;
    color: #fff;
    font-size: 2em;
    text-align: center;
    font-weight: 600;
    font-family: "Thevoice", Helvetica;
    line-height: 1.5;
  }
}
