.carousel-container {
  max-width: 1200px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
  overflow: hidden;
}

.swiper_container {
  width: 95%;
  height: auto;
}

.swiper-slide img {
  border-radius: 10px;
  display: block;
  width: 100%;
  height: auto;
}

.slider-controler {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.slider-arrow {
  display: none;
  cursor: pointer;
  font-size: 24px;
}

.swiper-pagination {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 480px) {
  .carousel-container {
    overflow: hidden;
  }
  .swiper_container {
    overflow: hidden;
  }
}

@media (min-width: 481px) and (max-width: 1223px) {
  .carousel-container{
    overflow: hidden;
  }
  .swiper_container {
    overflow: hidden;
  }
}
