@media (max-width: 480px) {
  .Proof {
    background: #212025;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 0;
    width: 100%;
  }
  .proof-title {
    background: linear-gradient(
      180deg,
      rgb(237, 26, 59) 0%,
      rgb(245, 131, 69) 100%
    );
    -webkit-background-clip: text !important;
    font-family: "SVN-Gilroy-Heavy", Helvetica;
    font-weight: 400;
    color: transparent;
    font-size: 1.5rem;
    padding-left: 20px;
  }

  .proof-row1 {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    .proof-img {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding-bottom: 40px;
      width: 40%;
      img {
        width: 100%;
        height: 250px;
        border-radius: 30px;
      }
    }
  }

  .title-proof {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    color: #ffffff;
    font-size: 1rem;
    text-align: center;
    padding-top: 10px;
    width: 90%;
  }
  .proof-body {
    width: 100%;
    padding-top: 40px;
    .proofItems {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
  }
}

@media (min-width: 481px) and (max-width: 1223px) {
  .Proof {
    background: #212025;
    display: block;
    width: 100%;
    padding: 40px 0;
  }

  .proof-container {
    width: 90%;
    margin: 0 auto;
  }
  .proof-title {
    background: linear-gradient(
      180deg,
      rgb(237, 26, 59) 0%,
      rgb(245, 131, 69) 100%
    );
    -webkit-background-clip: text !important;
    font-family: "SVN-Gilroy-Heavy", Helvetica;
    font-weight: 400;
    color: transparent;
    font-size: 1.5rem;
    padding-left: 20px;
  }

  .proof-row1 {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    .proof-img {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding-bottom: 40px;
      width: 40%;
      img {
        width: 100%;
        border-radius: 30px;
      }
    }
  }
  .title-proof {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    color: #ffffff;
    font-size: 1.5rem;
    text-align: center;
    padding: 10px 10px;
    line-height: 1.5;
  }
  .proof-img {
    img {
      width: 100%;
      border-radius: 30px;
    }
  }

  .proof-body {
    width: 100%;
    padding-top: 40px;
    .proofItems {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
  }
  .proof-img {
    img {
      width: 100%;
      height: 370px;
      border-radius: 30px;
    }
  }
}

@media (min-width: 1224px) {
  .Proof {
    background: #212025;
    display: block;
    padding: 40px 0;
    width: 100%;
  }

  .proof-container {
    max-width: 1200px;
    margin: 0 auto;
  }
  .proof-title {
    background: linear-gradient(
      180deg,
      rgb(237, 26, 59) 0%,
      rgb(245, 131, 69) 100%
    );
    -webkit-background-clip: text !important;
    font-family: "SVN-Gilroy-Heavy", Helvetica;
    font-weight: 400;
    color: transparent;
    font-size: 1.5rem;
    padding-left: 20px;
  }

  .proof-row1 {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
    width: 95%;
    gap: 20px;
    .proof-img {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
    }
    .title-proof {
      font-family: "Roboto", sans-serif;
      font-weight: 500;
      color: #ffffff;
      font-size: 1.5rem;
      text-align: center;
      padding: 10px 10px;
      line-height: 1.5;
    }
    .proof-img {
      img {
        width: 100%;
        height: 370px;
        border-radius: 30px;
      }
    }
  }

  .proof-body {
    width: 100%;
    padding-top: 40px;
    .proofItems {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      .proof-img {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        transition: transform 0.3s ease;
        cursor: pointer;
      }

      .proof-img:hover {
        transform: scale(1.1);
      }
    }
  }
}
