@media (max-width: 480px) {
  .Coupon {
    width: 100%;
    justify-content: center;
    background: #212025;
    padding-top: 40px;
  }

  .coupon-container {
    max-width: 1200px;
    margin: 0 auto;
  }
  .coupon-title {
    background: linear-gradient(
      180deg,
      rgb(237, 26, 59) 0%,
      rgb(245, 131, 69) 100%
    );
    -webkit-background-clip: text !important;
    font-family: "SVN-Gilroy-Heavy", Helvetica;
    font-weight: 400;
    color: transparent;
    font-size: 1.5rem;
    padding-left: 20px;
  }

  .coupon-row1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .coupon-row2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    width: 100%;
  }

  .title-coupon {
    font-family: "SVN-Gilroy-Bold", Helvetica;
    font-weight: 700;
    color: #ffffff;
    font-size: 1.5rem;
    text-align: center;
    padding: 0 10px;
    width: 90%;
  }
  .coupon-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow: hidden;
    .videoItems {
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding-top: 40px;
      width: 100%;
      .coupon-link {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        transition: transform 0.3s ease;
        cursor: pointer;
        align-items: center;
        video {
          width: 90%;
          border-radius: 30px;
        }
      }
    }
  }
}

@media (min-width: 481px) and (max-width: 1223px) {
  .Coupon {
    width: 90%;
    justify-content: center;
    background: #212025;
    padding-top: 40px;
  }

  .coupon-container {
    max-width: 1200px;
    margin: 0 auto;
  }
  .coupon-title {
    background: linear-gradient(
      180deg,
      rgb(237, 26, 59) 0%,
      rgb(245, 131, 69) 100%
    );
    -webkit-background-clip: text !important;
    font-family: "SVN-Gilroy-Heavy", Helvetica;
    font-weight: 400;
    color: transparent;
    font-size: 1.5rem;
    padding-left: 20px;
  }

  .coupon-row1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .coupon-row2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    width: 100%;
  }

  .title-coupon {
    font-family: "SVN-Gilroy-Bold", Helvetica;
    font-weight: 700;
    color: #ffffff;
    font-size: 1.5rem;
    text-align: center;
    padding: 0 10px;
    width: 90%;
  }
  .coupon-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow: hidden;
    .videoItems {
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding-top: 40px;
      width: 100%;
      .coupon-link {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        transition: transform 0.3s ease;
        cursor: pointer;
        align-items: center;
        video {
          width: 50%;
          border-radius: 30px;
        }
      }
    }
  }
}

@media (min-width: 1224px) {
  .Coupon {
    width: 100%;
    justify-content: center;
    background: #212025;
    padding-top: 40px;
  }

  .coupon-container {
    max-width: 1200px;
    margin: 0 auto;
  }
  .coupon-title {
    background: linear-gradient(
      180deg,
      rgb(237, 26, 59) 0%,
      rgb(245, 131, 69) 100%
    );
    -webkit-background-clip: text !important;
    font-family: "SVN-Gilroy-Heavy", Helvetica;
    font-weight: 400;
    color: transparent;
    font-size: 1.5rem;
    padding-left: 20px;
  }

  .coupon-row1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .coupon-row2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    width: 100%;
  }

  .title-coupon {
    font-family: "SVN-Gilroy-Bold", Helvetica;
    font-weight: 700;
    color: #ffffff;
    font-size: 1.5rem;
    text-align: center;
    padding: 0 10px;
    width: 90%;
  }
  .coupon-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow: hidden;
    .videoItems {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding-top: 40px;
      width: 100%;
      .coupon-link {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        transition: transform 0.3s ease;
        cursor: pointer;
        align-items: center;
        video {
          border-radius: 30px;
          width: 95%;
        }
      }
    }
  }
}
