@media (min-width: 1224px) {
  .footer {
    box-sizing: border-box;
    width: 100%;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: white;
  }

  .footer-container {
    max-width: 1200px;
  }

  .footer-row1 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  .item-row1 {
    flex: 1;
    gap: 20px;

    img {
      width: 90%;
    }
  }

  .social {
    flex: 1;
    display: flex;
    flex-direction: row;
    img {
      padding-right: 10px;
    }
  }

  .social a {
    transition: transform 0.3s ease;
  }

  .social a:hover {
    transform: scale(1.1);
  }

  .text-wrapper-12 {
    font-family: "SVN-Gilroy-Medium", Helvetica;
    font-weight: 500;
    font-size: 1.5em;
    color: #575757d9;
    letter-spacing: 0;
    line-height: normal;
    white-space: nowrap;
    width: 100%;
    padding-bottom: 5px;
  }

  .vectorFoot {
    padding-top: 60px;
  }

  .footer-row2 {
    padding: 20px 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  .payment {
    width: 80%;
  }

  .footer-row3 {
    padding-bottom: 20px;
  }

  .group-34 {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-bottom: 10px;
    .footer-link-col1,
    .footer-link-col2 {
      display: flex;
      flex-direction: row;
      gap: 30px;
      text-align: center;
    }
    .footer-link-col1 {
      padding-right: 30px;
    }
    .footer-breaker {
      display: none;
    }
  }

  .text-wrapper-18 {
    font-family: "SVN-Gilroy-Medium", Helvetica;
    font-weight: 500;
    color: #575757;
    font-size: 1.3em;
    letter-spacing: 0;
    line-height: normal;
    white-space: nowrap;
    text-decoration: underline;
  }

  .row4 {
    text-align: center;
    .text-wrapper-18 {
      text-decoration: none;
    }
  }

  .footer-row4 {
    text-align: center;
    .text-wrapper-18 {
      text-decoration: none;
    }
  }
}
@media (min-width: 481px) and (max-width: 1223px) {
  .footer {
    box-sizing: border-box;
    width: 100%;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: white;
  }

  .footer-container {
    width: 100%;
  }

  .footer-row1 {
    display: flex;
    gap: 10px;
    flex-direction: row;
    align-items: flex-start;
  }

  .item-row1 {
    flex: 1;
    gap: 20px;
    img {
      width: 100%;
    }
  }
  .item-row1:nth-child(4) {
    display: none;
  }
  .social {
    flex: 1;
    display: flex;
    flex-direction: row;
    img {
      padding-right: 10px;
    }
  }

  .social a {
    transition: transform 0.3s ease;
  }

  .social a:hover {
    transform: scale(1.1);
  }

  .text-wrapper-12 {
    font-family: "SVN-Gilroy-Medium", Helvetica;
    font-weight: 500;
    font-size: 1.3em;
    color: #575757d9;
    letter-spacing: 0;
    line-height: normal;
    white-space: nowrap;
    width: 100%;
    padding-bottom: 5px;
    text-decoration: underline;
  }

  .vectorFoot {
    padding-top: 60px;
  }

  .footer-row2 {
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .item-row21 {
      img {
        width: 100%;
      }
    }
  }

  .payment {
    width: 95%;
  }

  .footer-row3 {
    padding-bottom: 20px;
  }

  .group-34 {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-bottom: 10px;
    .footer-link-col1,
    .footer-link-col2 {
      display: flex;
      flex-direction: row;
      gap: 30px;
      text-align: center;
      justify-content: space-around;
    }
    .footer-breaker {
      display: none;
    }
  }

  .text-wrapper-18 {
    font-family: "SVN-Gilroy-Medium", Helvetica;
    font-weight: 500;
    color: #575757;
    font-size: 1em;
    letter-spacing: 0;
    line-height: normal;
    white-space: nowrap;
    text-decoration: underline;
  }

  .footer-row4 {
    text-align: center;
    .text-wrapper-18 {
      text-decoration: none;
    }
  }
}

@media (max-width: 480px) {
  .footer {
    font-family: "SVN-Gilroy-Medium", Helvetica;
    padding: 10px;
    background: white;
    width: 100%;
  }

  .footer-row1 {
    display: flex;
    justify-content: space-between;
    .item-row1:nth-of-type(4),
    .item-row1:nth-of-type(5) {
      display: none;
    }
    padding-bottom: 20px;
  }
  .text-wrapper-12 {
    font-size: 0.8em;
  }

  .item-row1 {
    img {
      width: 90%;
    }
    .social {
      flex: 1;
      display: flex;
      flex-direction: row;
    }
  }
  .footer-row2 {
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  .item-row21 {
    img {
      width: 100%;
    }
    .guarantee {
      width: 40%;
      padding: 10px;
    }
  }
  .group-34 {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    row-gap: 10px;
    padding-bottom: 10px;
    gap: 10px;
    .footer-link-col1 {
      text-align: right;
    }
    .footer-breaker {
      content: "";
      border: 1px solid black;
      border-radius: 1px;
    }
  }
  .text-wrapper-18 {
    font-size: 0.8em;
    text-decoration: underline;
  }

  .item-row2 {
    padding-bottom: 20px;
  }
  .footer-row4 {
    text-align: center;
    .text-wrapper-18 {
      text-decoration: none;
    }
  }
}
