@media (max-width: 480px) {
  .data {
    width: 100%;    
    padding-top: 40px;
    background: #1d1d1f;
    overflow: hidden;
  }
  .data-container {
    display: flex;
    justify-content: center;
  }
  .dataItem {
    width: 90%;
    border: 1px solid red;
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-family: "SVN-Gilroy-Heavy", Helvetica;
    font-weight: 400;
    color: #ffffff;
    box-shadow: 0px 2.93px 2.47px #00000042;
    background: linear-gradient(
      to right,
      rgb(237, 26, 59) 0%,
      rgb(245, 131, 69) 100%
    );
    font-size: 1.2rem;
    border-radius: 20px;
  }
  .title-content {
    text-align: center;
    padding: 10px 20px;
  }
  .body-content {
    border-radius: 0 0 20px 20px;
    background: linear-gradient(to right, #3a3842 0%, #232127 100%);
  }

  .element21 {
    width: 100%;
  }
  .plusImg {
    padding-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    .plus50,
    .plus100,
    .plus500 {
      padding: 10px;
      width: 50%;
    }
    .plus50Img {
      width: 100%;
    }
    .plus50-num {
      text-shadow: 8px 5px 10.2px #000000;
      font-family: "SVN-Gilroy-Black", Helvetica;
      font-weight: 900;
      font-size: 1.5em;
      text-align: center;
    }
  }

  .money {
    color: white;
    font-weight: 600;
    transition: transform 1.5s cubic-bezier(0.25, 0.1, 0.25, 1),
      color 1.5s cubic-bezier(0.25, 0.1, 0.25, 1);
  }

  .money.highlight {
    color: yellow;
    font-weight: 600;
    transform: scale(1.5);
  }

  @keyframes shine {
    0%,
    10% {
      background-position: top left;
    }
    100% {
      background-position: top right;
    }
  }

  .plus50-text {
    background: linear-gradient(
      180deg,
      rgb(237, 26, 59) 0%,
      rgb(245, 131, 69) 100%
    );
    -webkit-background-clip: text !important;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "SVN-Gilroy-Regular", Helvetica;
    font-weight: 700;
    font-size: 0.9em;
    padding: 10px 0;
    text-align: left;
  }
}

@media (min-width: 481px) and (max-width: 1223px) {
  .data {
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
    background: #1d1d1f;
    overflow: hidden;
  }

  .dataItem {
    max-width: 1200px;
    border: 1px solid red;
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-family: "SVN-Gilroy-Heavy", Helvetica;
    font-weight: 400;
    color: #ffffff;
    box-shadow: 0px 2.93px 2.47px #00000042;
    background: linear-gradient(
      to right,
      rgb(237, 26, 59) 0%,
      rgb(245, 131, 69) 100%
    );
    font-size: 0.8rem;
    padding-top: 1rem;
    border-radius: 60px;
  }
  .title-content {
    text-align: center;
    padding: 20px;
  }
  .body-content {
    background: linear-gradient(to right, #3a3842 0%, #232127 100%);
    border-radius: 0 0 60px 60px;
  }

  .plusImg {
    padding: 20px 0;
  }

  .element21 {
    width: 100%;
  }

  .plus50,
  .plus100,
  .plus500 {
    padding: 10px;
  }

  .plus50-num {
    text-shadow: 8px 5px 10.2px #000000;
    font-family: "SVN-Gilroy-Black", Helvetica;
    font-weight: 900;
    font-size: 2.3em;
    text-align: right;
    padding-right: 50px;
  }

  .money {
    color: white;
    font-weight: 600;
    transition: transform 1.5s cubic-bezier(0.25, 0.1, 0.25, 1),
      color 1.5s cubic-bezier(0.25, 0.1, 0.25, 1);
  }

  .money.highlight {
    color: yellow;
    font-weight: 600;
    transform: scale(1.5);
  }

  @keyframes shine {
    0%,
    10% {
      background-position: top left;
    }
    100% {
      background-position: top right;
    }
  }

  .plus50-text {
    background: linear-gradient(
      180deg,
      rgb(237, 26, 59) 0%,
      rgb(245, 131, 69) 100%
    );
    -webkit-background-clip: text !important;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "SVN-Gilroy-Regular", Helvetica;
    font-weight: 700;
    padding: 10px 0;
    padding-right: 30px;
    font-size: 1.8em;
    text-align: right;
  }

  .plus50Img {
    width: 100%;
  }

  .plus {
    width: 50%;
    padding: 10px;
  }
  .data {
    background: none;
  }
  .title-content {
    font-size: 2em;
  }
  .body-content {
    display: flex;
    flex-direction: row;
    .handImg {
      flex: 2;
    }

    .plus100 {
      padding: 5% 0;
    }
    .plusImg {
      flex: 1;
      display: flex;
      flex-direction: column;
      width: 100%;
      padding-right: 2%;
      img {
        padding: 4% 0;
        width: 100%;
      }
    }
  }
}

@media (min-width: 1224px) {
  .data {
    width: 100%;
    display: block;
    padding-top: 40px;
    background: #1d1d1f;
    overflow: hidden;
  }
  .data-container {
    margin: 0 auto;
    max-width: 1200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dataItem {
    width: 95%;
    border: 1px solid red;
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-family: "SVN-Gilroy-Heavy", Helvetica;
    font-weight: 400;
    color: #ffffff;
    box-shadow: 0px 2.93px 2.47px #00000042;
    background: linear-gradient(
      to right,
      rgb(237, 26, 59) 0%,
      rgb(245, 131, 69) 100%
    );
    font-size: 1rem;
    padding-top: 1rem;
    border-radius: 60px;
  }
  .title-content {
    text-align: center;
    padding-bottom: 20px;
  }
  .body-content {
    background: linear-gradient(to right, #3a3842 0%, #232127 100%);
    border-radius: 0 0 60px 60px;
  }

  .plusImg {
    padding: 20px 0;
  }

  .element21 {
    width: 100%;
  }

  .plus50,
  .plus100,
  .plus500 {
    padding: 10px;
  }

  .plus50-num {
    text-shadow: 8px 5px 10.2px #000000;
    font-family: "SVN-Gilroy-Black", Helvetica;
    font-weight: 900;
    font-size: 3em;
    text-align: right;
    padding-right: 50px;
  }

  .money {
    color: white;
    font-weight: 600;
    transition: transform 1.5s cubic-bezier(0.25, 0.1, 0.25, 1),
      color 1.5s cubic-bezier(0.25, 0.1, 0.25, 1);
  }

  .money.highlight {
    color: yellow;
    font-weight: 600;
    transform: scale(1.5);
  }

  @keyframes shine {
    0%,
    10% {
      background-position: top left;
    }
    100% {
      background-position: top right;
    }
  }

  .plus50-text {
    background: linear-gradient(
      180deg,
      rgb(237, 26, 59) 0%,
      rgb(245, 131, 69) 100%
    );
    -webkit-background-clip: text !important;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "SVN-Gilroy-Regular", Helvetica;
    font-weight: 700;
    padding: 10px 0;
    padding-right: 30px;
    font-size: 1.8em;
    text-align: right;
  }

  .plus50Img {
    width: 100%;
  }

  .plus {
    width: 50%;
    padding: 10px;
  }
  .data {
    background: none;
  }
  .title-content {
    font-size: 2.6em;
  }
  .body-content {
    display: flex;
    flex-direction: row;
    .handImg {
      flex: 2;
    }

    .plus100 {
      padding: 5% 0;
    }
    .plusImg {
      flex: 1;
      display: flex;
      flex-direction: column;
      width: 100%;
      padding-right: 2%;
      img {
        padding: 4% 0;
        width: 100%;
      }
    }
  }
}
