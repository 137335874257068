@media (max-width: 480px) {
  .banner-wrapper {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .banner-col1,
  .banner-col3 {
    display: none;
  }
  .banner-col2 {
    video {
      margin: 0 auto;
      display: none;
      border-bottom-left-radius: 300px 20px;
      border-bottom-right-radius: 300px 20px;
      width: 98%;
    }
    video.active {
      display: block;
    }
  }
  .banner-wrapper {
    background: linear-gradient(
      to right,
      rgb(237, 26, 59) 0%,
      rgb(245, 131, 69) 100%
    );
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-bottom: 15px;
    border-bottom-left-radius: 300px 35px;
    border-bottom-right-radius: 300px 35px;
  }

  .frame-0 {
    width: 100%;
    overflow: hidden;
  }
  .logo1,
  .logo2 {
    display: none;
  }
  .frame-0 {
    width: 100%;
    display: flex;
    justify-content: center;
    align-self: center;
    object-fit: cover;

    video {
      border-bottom-right-radius: 1000px 100px;
      border-bottom-left-radius: 1000px 100px;
      padding-bottom: 5px;
      /*width: 100%;
      border-bottom: 20px solid transparent;
      box-shadow: 0px 2.93px 2.47px #00000042;*/
      border: 15px;
      background: linear-gradient(
        to right,
        rgb(237, 26, 59) 0%,
        rgb(245, 131, 69) 100%
      );
    }
  }
}
@media (min-width: 481px) and (max-width: 1223px) {
  .banner-wrapper {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .banner-col1,
  .banner-col3 {
    display: none;
  }
  .banner-col2 {
    video {
      margin: 0 auto;
      display: none;
      border-bottom-left-radius: 300px 20px;
      border-bottom-right-radius: 300px 20px;
      width: 98%;
    }
    video.active {
      display: block;
    }
  }
  .banner-wrapper {
    background: linear-gradient(
      to right,
      rgb(237, 26, 59) 0%,
      rgb(245, 131, 69) 100%
    );
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-bottom: 15px;
    border-bottom-left-radius: 300px 35px;
    border-bottom-right-radius: 300px 35px;
  }

  .frame-0 {
    width: 100%;
    overflow: hidden;
  }
  .logo1,
  .logo2 {
    display: none;
  }
  .frame-0 {
    width: 100%;
    display: flex;
    justify-content: center;
    align-self: center;
    object-fit: cover;

    video {
      border-bottom-right-radius: 1000px 100px;
      border-bottom-left-radius: 1000px 100px;
      padding-bottom: 5px;
      /*width: 100%;
      border-bottom: 20px solid transparent;
      box-shadow: 0px 2.93px 2.47px #00000042;*/
      border: 15px;
      background: linear-gradient(
        to right,
        rgb(237, 26, 59) 0%,
        rgb(245, 131, 69) 100%
      );
    }
  }
}

@media (min-width: 1224px) {
  .banner-wrapper {
    background: linear-gradient(
      to right,
      rgb(237, 26, 59) 0%,
      rgb(245, 131, 69) 100%
    );
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-bottom: 15px;
    border-radius: 0 0 30px 30px;
  }

  .banner-col1,
  .banner-col3 {
    flex: 1;
    display: flex;
    justify-content: center;
  }
  .banner-col2 {
    width: 98%;
    display: flex;
    justify-content: center;
    video {
      border-radius: 30px;
      margin: 0 auto;
      width: 100%;
    }
  }
  .logo1 {
    display: flex;
    align-items: center;
    width: 90%;
    display: none;
  }
  .logo2 {
    width: 90%;
    display: none;
  }

  .banner-col1.sticky,
  .banner-col3.sticky {
    position: fixed;
    top: 0;
    z-index: 1000;
  }

  .banner-col1.sticky {
    left: 0;
    width: 19%;
  }

  .banner-col3.sticky {
    right: 0;
    width: 19%;
  }
}
