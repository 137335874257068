@font-face {
  font-family: "UTM-Avo";
  src: url("../fonts/UTM-Avo.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@media (max-width: 480px) {
  .feedback {
    background: #212025;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .feedback-container {
    max-width: 1200px;
  }

  .title-feedback {
    overflow: hidden;
    display: flex;
    justify-content: center;
    opacity: 0.7;
  }

  .feedback-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .content-feedback {
    width: 90%;
    position: relative;
    color: white;
    font-size: 1.5em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
  }

  .content-feedback::before {
    position: absolute;
    top: 10px;
    content: "";
    width: 100%;
    border: 1px solid white;
  }

  .feedback-col1 {
    padding-top: 20px;
    display: flex;
    justify-content: center;
    font-size: 1em;
    color: white;
    font-family: "Roboto", sans-serif;
    text-align: justify;
    line-height: 1.2;
    width: 90%;
  }

  .feedback-col2 {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    width: 90%;
    gap: 10px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 30px;
    }
  }
  .item {
    font-family: "SVN-Gilroy-regular", Helvetica;
    transition: transform 0.3s ease;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .item:hover {
    transform: scale(1.1);
  }

  #feedback-next,
  #feedback-prev {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    color: white;
    background-color: transparent;
    border: none;
    font-size: 20px;
    padding: 0 200px;
    font-size: 50px;
    z-index: 10;
  }

  #feedback-next {
    left: 0;
  }

  .feedback-carousel-indicators {
    position: relative;
    text-align: center; /* Căn giữa các chấm theo dõi */
    margin-top: 10px; /* Khoảng cách với carousel */
    z-index: 10;
  }

  .feedback-indicator {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%; /* Để làm cho chấm tròn */
    background-color: #333; /* Màu nền mặc định cho các chấm */
    margin: 0 5px; /* Khoảng cách giữa các chấm */
  }

  .feedback-indicator.active {
    background-color: #bbb; /* Màu nền cho chấm hiện tại */
  }

  .feedback-other {
    position: relative;
    display: flex;
    justify-content: center;
  }

  .feedback-body {
    width: 100%;
    .feedback-wrapper-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 95%;
    }
  }
}

@media (min-width: 481px) and (max-width: 1223px) {
  .feedback {
    background: #212025;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
  }

  .feedback-container {
    max-width: 1200px;
  }

  .title-feedback {
    overflow: hidden;
    display: flex;
    justify-content: center;
    opacity: 0.7;
  }

  .content-feedback {
    width: 90%;
    position: relative;
    color: white;
    padding-top: 40px;
    font-size: 1.5em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .content-feedback::before {
    position: absolute;
    top: 20px;
    content: "";
    width: 100%;
    border: 1px solid white;
  }

  .feedback-col1 {
    padding-top: 50px;
    display: flex;
    justify-content: center;
    font-size: 2em;
    color: white;
    font-family: "Roboto", sans-serif;
    text-align: center;
    line-height: 1.2;
  }

  .feedback-col2 {
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 10px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 30px;
    }
  }
  .item {
    width: 25%;
    font-family: "SVN-Gilroy-regular", Helvetica;
    transition: transform 0.3s ease;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      border-radius: 40px;
    }
  }
  .item:hover {
    transform: scale(1.1);
  }

  #feedback-next,
  #feedback-prev {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    color: white;
    background-color: transparent;
    border: none;
    font-size: 20px;
    padding: 0 200px;
    font-size: 50px;
    z-index: 10;
  }

  #feedback-next {
    left: 0;
  }

  .feedback-carousel-indicators {
    position: relative;
    text-align: center; /* Căn giữa các chấm theo dõi */
    margin-top: 10px; /* Khoảng cách với carousel */
    z-index: 10;
  }

  .feedback-indicator {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%; /* Để làm cho chấm tròn */
    background-color: #333; /* Màu nền mặc định cho các chấm */
    margin: 0 5px; /* Khoảng cách giữa các chấm */
  }

  .feedback-indicator.active {
    background-color: #bbb; /* Màu nền cho chấm hiện tại */
  }

  .feedback-other {
    position: relative;
    display: flex;
    justify-content: center;
  }

  .feedback-body {
    width: 100%;
    display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    .feedback-wrapper-body {      
      width: 95%;
    }
  }
}

@media (min-width: 1224px) {
  .feedback {
    background: #212025;
    display: block;
    padding-top: 30px;
  }

  .feedback-container {
    max-width: 1200px;
    margin: 0 auto;
  }

  .title-feedback {
    overflow: hidden;
    display: flex;
    justify-content: center;
    opacity: 0.7;
  }

  .content-feedback {
    width: 90%;
    position: relative;
    color: white;
    padding-top: 40px;
    font-size: 1.5em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .content-feedback::before {
    position: absolute;
    top: 20px;
    content: "";
    width: 100%;
    border: 1px solid white;
  }

  .feedback-col1 {
    padding: 40px 0;
    display: flex;
    justify-content: center;
    font-size: 2em;
    color: white;
    font-family: "Roboto", sans-serif;
    text-align: center;
    line-height: 1.2;
  }

  .feedback-col2 {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 20px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .item {
    width: 25%;
    font-family: "SVN-Gilroy-regular", Helvetica;
    transition: transform 0.3s ease;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      border-radius: 40px;
    }
  }
  .item:hover {
    transform: scale(1.1);
  }

  #feedback-next,
  #feedback-prev {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    color: white;
    background-color: transparent;
    border: none;
    font-size: 20px;
    padding: 0 200px;
    font-size: 50px;
    z-index: 10;
  }

  #feedback-next {
    left: 0;
  }

  .feedback-carousel-indicators {
    position: relative;
    text-align: center; /* Căn giữa các chấm theo dõi */
    margin-top: 10px; /* Khoảng cách với carousel */
    z-index: 10;
  }

  .feedback-indicator {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%; /* Để làm cho chấm tròn */
    background-color: #333; /* Màu nền mặc định cho các chấm */
    margin: 0 5px; /* Khoảng cách giữa các chấm */
  }

  .feedback-indicator.active {
    background-color: #bbb; /* Màu nền cho chấm hiện tại */
  }

  .feedback-other {
    position: relative;
    display: flex;
    justify-content: center;
  }

  .feedback-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .feedback-wrapper-body {
      width: 95%;
    }
  }
}
