@media (max-width: 420px) {
  .bossContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 40vh;
    overflow: hidden;
  }
  .bossbox {
    position: relative;
    transform-style: preserve-3d;
    perspective: 500px;
  }

  .bossitem {
    position: absolute;
    top: 0%;
    left: 27%;
    width: 45%;
    padding-top: -40px;
    transform-style: preserve-3d;
    transform-origin: bottom;
    user-select: none;
    transition: transform 1s ease, opacity 1s ease;
    box-shadow: 0 0 10px, rgba(0, 0, 0, 0.5);
  }
  .bossbox .bossitem img {
    width: 100%;
  }

  .bossbox .bossitem:nth-child(1),
  .bossitem:nth-child(6),
  .bossitem:nth-child(7),
  .bossitem:nth-child(8),
  .bossitem:nth-child(9),
  .bossitem:nth-child(10),
  .bossitem:nth-child(11),
  .bossitem:nth-child(12),
  .bossitem:nth-child(13),
  .bossitem:nth-child(14),
  .bossitem:nth-child(15),
  .bossitem:nth-child(16),
  .bossitem:nth-child(17) {
    transform: translate3d(0, 0, -150px) scale(0.9);
    opacity: 0;
    z-index: 1;
  }

  .bossbox .bossitem:nth-child(2) {
    transform: translate3d(-130px, 0, -150px) scale(0.9) rotateY(-15deg);
    opacity: 0.4;
    z-index: 2;
  }

  .bossbox .bossitem:nth-child(3) {
    transform: translate3d(0, 0, 0) scale(1);
    opacity: 1;
    z-index: 3;
  }

  .bossbox .bossitem:nth-child(4) {
    transform: translate3d(130px, 0, -150px) scale(0.9) rotateY(15deg);
    opacity: 0.4;
    z-index: 2;
  }

  .bossbox .bossitem:nth-child(5) {
    transform: translate3d(0, 0, -150px) scale(0.9);
    opacity: 0;
    z-index: 1;
  }
  .moving-out {
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
    opacity: 0.5;
  }

  .bossprev,
  .bossnext {
    display: none;
    position: absolute;
    bottom: -320px;
    left: 120px;
    font-size: 1.5em;
    z-index: 10;
    color: white;
    cursor: pointer;
  }
  .bossnext {
    left: 270px;
  }
}

@media (min-width: 481px) and (max-width: 1223px) {
  .bossContainer {
    display: flex;
    flex-direction: column;
    height: 400px;
  }

  .bossbox {
    position: relative;
    transform-style: preserve-3d;
    perspective: 500px;
  }

  .bossitem {
    position: absolute;
    top: 0;
    right: -200px;
    width: 300px;
    height: 100px;
    transform-style: preserve-3d;
    transform-origin: bottom;
    user-select: none;
    transition: transform 1s ease, opacity 1s ease;
    box-shadow: 0 0 50px, rgba(0, 0, 0, 0.5);
  }
  .bossbox .bossitem img {
    width: 80%;
  }

  .bossbox .bossitem:nth-child(1),
  .bossitem:nth-child(6),
  .bossitem:nth-child(7),
  .bossitem:nth-child(8),
  .bossitem:nth-child(9),
  .bossitem:nth-child(10),
  .bossitem:nth-child(11),
  .bossitem:nth-child(12),
  .bossitem:nth-child(13),
  .bossitem:nth-child(14),
  .bossitem:nth-child(15),
  .bossitem:nth-child(16),
  .bossitem:nth-child(17) {
    transform: translate3d(0, 0, -300px) scale(0.9);
    opacity: 0;
    z-index: 1;
  }

  .bossbox .bossitem:nth-child(2) {
    transform: translate3d(-250px, 50px, -150px) scale(0.9) rotateY(-10deg);
    opacity: 0.4;
    z-index: 2;
  }

  .bossbox .bossitem:nth-child(3) {
    transform: translate3d(0, 0, 0) scale(1);
    opacity: 1;
    z-index: 3;
  }

  .bossbox .bossitem:nth-child(4) {
    transform: translate3d(250px, 50px, -150px) scale(0.9) rotateY(10deg);
    opacity: 0.4;
    z-index: 2;
  }

  .bossbox .bossitem:nth-child(5) {
    transform: translate3d(0, 0, -300px) scale(0.9);
    opacity: 0;
    z-index: 1;
  }

  .moving-out {
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
    opacity: 0.5;
  }

  .bossprev,
  .bossnext {
    position: absolute;
    bottom: -400px;
    left: -100px;
    font-size: 2em;
    z-index: 10;
    color: white;
    cursor: pointer;
  }
  .bossnext {
    left: 100px;
  }
}
@media (min-width: 421px) and (max-width: 480px) {
  .bossContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 40vh;
  }
  .bossbox {
    position: relative;
    transform-style: preserve-3d;
    perspective: 500px;
  }

  .bossitem {
    position: absolute;
    top: 0%;
    left: 25%;
    width: 50%;
    padding-top: -40px;
    transform-style: preserve-3d;
    transform-origin: bottom;
    user-select: none;
    transition: transform 1s ease, opacity 1s ease;
    box-shadow: 0 0 10px, rgba(0, 0, 0, 0.5);
  }
  .bossbox .bossitem img {
    width: 100%;
  }

  .bossbox .bossitem:nth-child(1),
  .bossitem:nth-child(6),
  .bossitem:nth-child(7),
  .bossitem:nth-child(8),
  .bossitem:nth-child(9),
  .bossitem:nth-child(10),
  .bossitem:nth-child(11),
  .bossitem:nth-child(12),
  .bossitem:nth-child(13),
  .bossitem:nth-child(14),
  .bossitem:nth-child(15),
  .bossitem:nth-child(16),
  .bossitem:nth-child(17) {
    transform: translate3d(0, 0, -150px) scale(0.9);
    opacity: 0;
    z-index: 1;
  }

  .bossbox .bossitem:nth-child(2) {
    transform: translate3d(-130px, 0, -150px) scale(0.9) rotateY(-15deg);
    opacity: 0.4;
    z-index: 2;
  }

  .bossbox .bossitem:nth-child(3) {
    transform: translate3d(0, 0, 0) scale(1);
    opacity: 1;
    z-index: 3;
  }

  .bossbox .bossitem:nth-child(4) {
    transform: translate3d(130px, 0, -150px) scale(0.9) rotateY(15deg);
    opacity: 0.4;
    z-index: 2;
  }

  .bossbox .bossitem:nth-child(5) {
    transform: translate3d(0, 0, -150px) scale(0.9);
    opacity: 0;
    z-index: 1;
  }
  .moving-out {
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
    opacity: 0.5;
  }

  .bossprev,
  .bossnext {
    position: absolute;
    bottom: -370px;
    left: 150px;
    font-size: 1.5em;
    z-index: 10;
    color: white;
    cursor: pointer;
  }
  .bossnext {
    left: 270px;
  }
}

@media (min-width: 481px) and (max-width: 1223px) {
  .bossContainer {
    display: flex;
    flex-direction: column;
    height: 400px;
  }

  .bossbox {
    position: relative;
    transform-style: preserve-3d;
    perspective: 500px;
  }

  .bossitem {
    position: absolute;
    top: 0;
    right: -200px;
    width: 300px;
    height: 100px;
    transform-style: preserve-3d;
    transform-origin: bottom;
    user-select: none;
    transition: transform 1s ease, opacity 1s ease;
    box-shadow: 0 0 50px, rgba(0, 0, 0, 0.5);
  }
  .bossbox .bossitem img {
    width: 80%;
  }

  .bossbox .bossitem:nth-child(1),
  .bossitem:nth-child(6),
  .bossitem:nth-child(7),
  .bossitem:nth-child(8),
  .bossitem:nth-child(9),
  .bossitem:nth-child(10),
  .bossitem:nth-child(11),
  .bossitem:nth-child(12),
  .bossitem:nth-child(13),
  .bossitem:nth-child(14),
  .bossitem:nth-child(15),
  .bossitem:nth-child(16),
  .bossitem:nth-child(17) {
    transform: translate3d(0, 0, -300px) scale(0.9);
    opacity: 0;
    z-index: 1;
  }

  .bossbox .bossitem:nth-child(2) {
    transform: translate3d(-250px, 50px, -150px) scale(0.9) rotateY(-10deg);
    opacity: 0.4;
    z-index: 2;
  }

  .bossbox .bossitem:nth-child(3) {
    transform: translate3d(0, 0, 0) scale(1);
    opacity: 1;
    z-index: 3;
  }

  .bossbox .bossitem:nth-child(4) {
    transform: translate3d(250px, 50px, -150px) scale(0.9) rotateY(10deg);
    opacity: 0.4;
    z-index: 2;
  }

  .bossbox .bossitem:nth-child(5) {
    transform: translate3d(0, 0, -300px) scale(0.9);
    opacity: 0;
    z-index: 1;
  }

  .moving-out {
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
    opacity: 0.5;
  }

  .bossprev,
  .bossnext {
    position: absolute;
    bottom: -400px;
    left: -100px;
    font-size: 2em;
    z-index: 10;
    color: white;
    cursor: pointer;
  }
  .bossnext {
    left: 100px;
  }
}

@media (min-width: 1224px) and (max-width: 1599px) {
  .bossContainer {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    height: 30vh;
  }

  .bossbox {
    position: relative;
    transform-style: preserve-3d;
    perspective: 500px;
  }

  .bossitem {
    position: absolute;
    top: -30px;
    left: 150px;
    width: 300px;
    height: 100px;
    transform-style: preserve-3d;
    transform-origin: bottom;
    user-select: none;
    transition: transform 1s ease, opacity 1s ease;
    box-shadow: 0 0 50px, rgba(0, 0, 0, 0.5);
  }
  .bossbox .bossitem img {
    width: 80%;
  }

  .bossbox .bossitem:nth-child(1),
  .bossitem:nth-child(6),
  .bossitem:nth-child(7),
  .bossitem:nth-child(8),
  .bossitem:nth-child(9),
  .bossitem:nth-child(10),
  .bossitem:nth-child(11),
  .bossitem:nth-child(12),
  .bossitem:nth-child(13),
  .bossitem:nth-child(14),
  .bossitem:nth-child(15),
  .bossitem:nth-child(16),
  .bossitem:nth-child(17) {
    transform: translate3d(0, 0, -300px) scale(0.9);
    opacity: 0;
    z-index: 1;
  }

  .bossbox .bossitem:nth-child(2) {
    transform: translate3d(-300px, 50px, -150px) scale(0.9) rotateY(-10deg);
    opacity: 0.4;
    z-index: 2;
  }

  .bossbox .bossitem:nth-child(3) {
    transform: translate3d(0, 0, 0) scale(1);
    opacity: 1;
    z-index: 3;
  }

  .bossbox .bossitem:nth-child(4) {
    transform: translate3d(270px, 50px, -150px) scale(0.9) rotateY(10deg);
    opacity: 0.4;
    z-index: 2;
  }

  .bossbox .bossitem:nth-child(5) {
    transform: translate3d(0, 0, -300px) scale(0.9);
    opacity: 0;
    z-index: 1;
  }

  .moving-out {
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
    opacity: 0.5;
  }

  .bossprev,
  .bossnext {
    position: absolute;
    bottom: -350px;
    left: 0%;
    font-size: 3em;
    z-index: 10;
    color: white;
    cursor: pointer;
  }
  .bossnext {
    left: 90%;
  }
}

@media (min-width: 1600px) {
  .bossContainer {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    height: 30vh;
  }

  .bossbox {
    position: relative;
    transform-style: preserve-3d;
    perspective: 500px;
  }

  .bossitem {
    position: absolute;
    top: -30px;
    left: 180px;
    width: 300px;
    height: 100px;
    transform-style: preserve-3d;
    transform-origin: bottom;
    user-select: none;
    transition: transform 1s ease, opacity 1s ease;
    box-shadow: 0 0 50px, rgba(0, 0, 0, 0.5);
  }
  .bossbox .bossitem img {
    width: 80%;
  }

  .bossbox .bossitem:nth-child(1),
  .bossitem:nth-child(6),
  .bossitem:nth-child(7),
  .bossitem:nth-child(8),
  .bossitem:nth-child(9),
  .bossitem:nth-child(10),
  .bossitem:nth-child(11),
  .bossitem:nth-child(12),
  .bossitem:nth-child(13),
  .bossitem:nth-child(14),
  .bossitem:nth-child(15),
  .bossitem:nth-child(16),
  .bossitem:nth-child(17) {
    transform: translate3d(0, 0, -300px) scale(0.9);
    opacity: 0;
    z-index: 1;
  }

  .bossbox .bossitem:nth-child(2) {
    transform: translate3d(-290px, 50px, -150px) scale(0.9) rotateY(-10deg);
    opacity: 0.4;
    z-index: 2;
  }

  .bossbox .bossitem:nth-child(3) {
    transform: translate3d(0, 0, 0) scale(1);
    opacity: 1;
    z-index: 3;
  }

  .bossbox .bossitem:nth-child(4) {
    transform: translate3d(260px, 50px, -150px) scale(0.9) rotateY(10deg);
    opacity: 0.4;
    z-index: 2;
  }

  .bossbox .bossitem:nth-child(5) {
    transform: translate3d(0, 0, -300px) scale(0.9);
    opacity: 0;
    z-index: 1;
  }

  .moving-out {
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
    opacity: 0.5;
  }

  .bossprev,
  .bossnext {
    position: absolute;
    bottom: -350px;
    left: 0;
    font-size: 3em;
    z-index: 10;
    color: white;
    cursor: pointer;
  }
  .bossnext {
    left: 90%;
  }
}
