@media (min-width: 1224px) {
  .modal {
    position: fixed;
    z-index: 20;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
  }

  .modal.open {
    display: block; /* Hiển thị modal khi modal được mở */
  }

  .modal-content {
    display: flex;
    justify-content: center;
    position: fixed; /* Đặt phần tử ở vị trí cố định so với cửa sổ trình duyệt */
    top: 50%; /* Đặt phần tử cách đỉnh của cửa sổ 50% chiều cao của cửa sổ */
    left: 50%; /* Đặt phần tử cách bên trái của cửa sổ 50% chiều rộng của cửa sổ */
    transform: translate(-50%, -50%);
    padding: 20px;
    border-radius: 5px;
    max-width: 800px;
    width: 100%;
    img {
      height: 90vh;
      border-radius: 50px;
    }
  }

  .close-button {
    color: white;
    top: 0;
    right: 0;
    display: flex;
    align-items: flex-start;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
}

@media (min-width: 481px) and (max-width: 1223px) {
  .modal {
    position: fixed;
    z-index: 20;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
  }

  .modal.open {
    display: block; /* Hiển thị modal khi modal được mở */
  }

  .modal-content {
    display: flex;
    justify-content: center;
    position: fixed; /* Đặt phần tử ở vị trí cố định so với cửa sổ trình duyệt */
    top: 50%; /* Đặt phần tử cách đỉnh của cửa sổ 50% chiều cao của cửa sổ */
    left: 50%; /* Đặt phần tử cách bên trái của cửa sổ 50% chiều rộng của cửa sổ */
    transform: translate(-50%, -50%);
    padding: 20px;
    border-radius: 5px;
    max-width: 800px;
    width: 100%;
    img {
      height: 90vh;
      width: auto;
      border-radius: 30px;
    }
  }

  .close-button {
    position: absolute;
    color: white;
    top: 0;
    right: 10%;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
}

@media (max-width: 480px) {
  .modal {
    position: fixed;
    z-index: 20;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
  }

  .modal.open {
    display: block; /* Hiển thị modal khi modal được mở */
  }

  .modal-content {
    display: flex;
    justify-content: center;
    position: fixed; /* Đặt phần tử ở vị trí cố định so với cửa sổ trình duyệt */
    top: 50%; /* Đặt phần tử cách đỉnh của cửa sổ 50% chiều cao của cửa sổ */
    left: 50%; /* Đặt phần tử cách bên trái của cửa sổ 50% chiều rộng của cửa sổ */
    transform: translate(-50%, -50%);
    padding: 20px;
    border-radius: 5px;
    max-width: 800px;
    width: 100%;
    img {
      width: 100%;
      height: auto;
      border-radius: 30px;
    }
  }

  .close-button {
    position: absolute;
    color: white;
    top: 0;
    right: 10%;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
}
