@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
* {
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}
html,
body {
  margin: 0px;
  height: 100%;
}
/* a blue color as a generic focus style */
button:focus-visible {
  outline: 2px solid #4a90e2 !important;
  outline: -webkit-focus-ring-color auto 5px !important;
}
a {
  text-decoration: none;
}

@font-face {
  font-family: "SVN-Gilroy-Medium";
  src: url("https://anima-uploads.s3.amazonaws.com/projects/6448932c1e0477c237c913b3/fonts/svn-gilroy-medium.woff")
    format("woff");
}

@font-face {
  font-family: "SVN-Gilroy-Regular";
  src: url("https://anima-uploads.s3.amazonaws.com/projects/6448932c1e0477c237c913b3/fonts/svn-gilroy-regular.woff")
    format("woff");
}

@font-face {
  font-family: "SVN-Gilroy-Heavy";
  src: url("https://anima-uploads.s3.amazonaws.com/projects/6541b619e1bdd603479e217f/fonts/svn-gilroy-heavy.otf")
    format("opentype");
}

@font-face {
  font-family: "SVN-Gilroy-Bold";
  src: url("https://anima-uploads.s3.amazonaws.com/projects/612fa6ae43d199a4d3d0222c/fonts/svn-gilroy-regular.otf")
    format("opentype");
}

@font-face {
  font-family: "SVN-Gilroy-SemiBold";
  src: url("https://anima-uploads.s3.amazonaws.com/projects/648b403a07db70cc46b6bc3f/fonts/svn-gilroy-semibold.otf")
    format("opentype");
}
